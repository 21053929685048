import { Box, Button, styled, Typography, useMediaQuery } from '@mui/material';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { Assets, theme, PigogoTextField, PigogoButton, PigogoSmallButton } from 'components';
import { emailValid } from '../../utils/validation';
import { isPlatform } from '@ionic/react';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  textAlign: 'center',
  gap: theme.spacing(3),
  boxSizing: 'border-box',
  justifyContent: 'center',
  background: theme.palette.error.contrastText,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    gap: theme.spacing(2),
  },
  width: '480px',
}));

interface Props {
  header: string;
  loading?: boolean;
  heroSection?: boolean;
  emailErrorMsg: string;
  headerDescription?: string;
  onClickFacebook: () => void;
  onClickGoogle: () => void;
  onClickApple?: () => void;
  onClickSignIn?: () => void;
  onClickTerms?: () => void;
  onClick: (email: string, password: string) => void;
}

const RegisterForm: FC<Props> = ({
  emailErrorMsg,
  header,
  headerDescription,
  loading,
  onClick,
  onClickFacebook,
  onClickSignIn,
  onClickTerms,
  onClickGoogle,
  onClickApple,
  heroSection,
}) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailMsgError, setEmailMsgError] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  useEffect(() => {
    if (emailErrorMsg && emailValid(email) && email.trim().length <= 100) {
      setEmailError(true);
      setEmailMsgError(emailErrorMsg);
    }
  }, [emailErrorMsg]);

  const handleRegister = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!emailValid(email) && email.trim() !== '') {
      setEmailError(true);
      setEmailMsgError('Το email δεν φαίνεται να είναι σωστό.');
    } else if (!emailValid(email) && email.trim() === '') {
      setEmailError(true);
      setEmailMsgError('Το πεδίο είναι υποχρεωτικό.');
    } else if (emailValid(email) && email.trim().length > 100) {
      setEmailError(true);
      setEmailMsgError('Οι χαρακτήρες δεν πρέπει να ξεπερνούν τους 100.');
    } else {
      setEmailError(false);
    }

    if (password.length < 6 || password.length > 200) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (password.length >= 6 && password.length <= 200 && emailValid(email) && email.trim().length <= 100) {
      onClick(email, password);
    }
  };

  const extractHeader = () => {
    if (smDown && heroSection) {
      return (
        <Typography variant="h4" textAlign="center" color={'#313D53'}>
          Γίνε μέλος δωρεάν!
        </Typography>
      );
    } else if (smDown) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={'body1'} color="primary">
            Γίνε μέλος δωρεάν!
          </Typography>
          {onClickSignIn && (
            <Button onClick={onClickSignIn}>
              <Typography
                variant={'body2MediumM'}
                color="#313D53"
                sx={{ textDecoration: 'underline', textDecorationColor: '#313D53', textUnderlineOffset: '5px' }}
              >
                Σύνδεση
              </Typography>
            </Button>
          )}
        </Box>
      );
    } else
      return (
        <Box display={'flex'} flexDirection={'column'} gap={'4px'} alignItems={'center'}>
          <Box>
            <Typography variant="h4" color={'#313D53'}>
              {header}
            </Typography>
          </Box>
          {headerDescription && (
            <Box maxWidth={'336px'}>
              <Typography variant="body2MediumM" color={'#313D53'}>
                {headerDescription}
              </Typography>
            </Box>
          )}
        </Box>
      );
  };

  const enterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleRegister(e);
    }
  };

  return (
    <StyleBox>
      <Box>{extractHeader()}</Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box>
          <PigogoTextField
            fullWidth
            label={'Εm\u200Aail'}
            value={email || null}
            size={'medium'}
            error={emailError}
            onFocus={() => {
              setEmailError(false);
            }}
            setValue={(value: any) => {
              setEmailError(false);
              setEmail(value);
            }}
            helperText={emailError && emailMsgError}
            onKeyDown={enterPress}
          />
        </Box>
        <Box>
          <PigogoTextField
            fullWidth
            label="Κωδικός"
            value={password}
            type={'password'}
            error={passwordError}
            autoComplete={'one-time-code'}
            onFocus={() => {
              setPasswordError(false);
            }}
            setValue={(value: any) => {
              setPasswordError(false);
              setPassword(value);
            }}
            helperText={
              passwordError
                ? password.trim() === ''
                  ? 'Το πεδίο είναι υποχρεωτικό.'
                  : password.trim().length > 200
                  ? 'Οι χαρακτήρες δεν πρέπει να ξεπερνούν τους 200.'
                  : 'Ο κωδικός πρέπει να είναι τουλάχιστον 6 χαρακτήρες.'
                : ''
            }
            onKeyDown={enterPress}
          />
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Box>
          <PigogoButton
            fullWidth
            variation="secondary"
            loading={loading}
            onClick={(e: any) => handleRegister(e)}
            text={'Δημιουργία λογαριασμού'}
          />
        </Box>
        <Box>
          <Typography
            variant={'subtitle1SmallM'}
            component="p"
            sx={{ fontWeight: 600, lineHeight: '24px', color: '#313D53' }}
          >
            ή
          </Typography>
        </Box>
        <Box display={'flex'} gap={2} flexDirection={heroSection ? 'column' : smDown ? 'column' : 'row'}>
          <Box width={smDown && !heroSection ? '100%' : '200px'}>
            <PigogoSmallButton
              fullWidth
              variant="outlined"
              onClick={onClickFacebook}
              startIcon={<Assets.Facebook />}
              sx={{ justifyContent: smDown && !heroSection ? 'center' : 'flex-start' }}
              typographyProps={{ variant: 'subtitle2' }}
              text={smDown && !heroSection ? 'Εγγραφή με Facebook' : 'Facebook'}
            />
          </Box>
          <Box width={smDown && !heroSection ? '100%' : '200px'}>
            <PigogoSmallButton
              fullWidth
              variant="outlined"
              onClick={onClickGoogle}
              startIcon={<Assets.Google />}
              sx={{ justifyContent: smDown && !heroSection ? 'center' : 'flex-start' }}
              typographyProps={{ variant: 'subtitle2' }}
              text={smDown && !heroSection ? 'Εγγραφή με Google' : 'Google'}
            />
          </Box>
          {isPlatform('ios') && (
            <Box width={smDown ? '100%' : '200px'}>
              <PigogoSmallButton
                fullWidth
                variant={'outlined'}
                startIcon={<Assets.Apple />}
                onClick={onClickApple}
                typographyProps={{ variant: 'subtitle2' }}
                text={smDown ? 'Εγγραφή με Apple' : 'Apple'}
                sx={{ justifyContent: smDown ? 'center' : 'flex-start' }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box
          gap={'6px'}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection={smDown ? 'column' : 'row'}
        >
          <Typography
            variant="captionM"
            color={'#313D53'}
            sx={{ display: smDown && !heroSection ? 'block' : 'inline' }}
          >
            Δημιουργώντας λογαριασμό αποδέχεσαι τους
          </Typography>
          <Typography
            color={'#313D53'}
            variant="captionM"
            onClick={onClickTerms}
            sx={{ textDecoration: 'underline', textDecorationColor: theme.palette.orange.main, cursor: 'pointer' }}
          >
            Όρους χρήσης
          </Typography>
        </Box>
      </Box>
      {onClickSignIn && !smDown && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box>
            <Typography color="primary" variant="body2MediumM" component="p">
              Έχεις ήδη λογαριασμό;
            </Typography>
          </Box>
          <Button onClick={onClickSignIn}>
            <Typography
              color="primary"
              sx={{ textDecoration: 'underline', textDecorationColor: 'primary', textUnderlineOffset: '5px' }}
              variant="buttonSmallM"
            >
              Κάνε σύνδεση
            </Typography>
          </Button>
        </Box>
      )}
    </StyleBox>
  );
};

export default RegisterForm;
