import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Step,
  StepContent,
  stepContentClasses,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as Euro } from '../../assets/svgs/euroPink.svg';
import { ReactComponent as Receipt } from '../../assets/svgs/receipt.svg';
import { ReactComponent as Chat } from '../../assets/svgs/chat.svg';
import { ReactComponent as CheckPink } from '../../assets/svgs/check_pink.svg';
import { Close, ExpandMore, InfoOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useGetUserDataQuery } from '../../redux/api/queries/userQueries';
import { Purchase } from '../../models/Purchase';
import PurchaseStatusInfo from './PurchaseStatusInfo';
import { ReactComponent as ChevronLeft } from '../../assets/svgs/chevronLeft24.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { theme } from 'components';

interface Props {
  isOpen: boolean;
  setOpen: (b: boolean) => void;
  selectedPurchase: Purchase | null;
  setSelectedPurchase: (selectedPurchase: Purchase | null) => void;
}

const StyledStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    zIndex: 1,
    fontSize: 18,
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: '#E9688A',
  },
  '& .QontoStepIcon-circle': {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: '#ECEFF4',
  },
}));

const PurchaseModal: FC<Props> = ({ isOpen, setOpen, selectedPurchase, setSelectedPurchase }) => {
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<{ label: string; description: string; date: string }[]>([]);
  const { data: userData } = useGetUserDataQuery();

  const extractToBePaidDescription = () => {
    if (!userData?.payment_method_id || (!userData?.payment_bank_iban && !userData?.viva_wallet_phone_number)) {
      return `Η αγορά σου έχει επιβεβαιωθεί από το κατάστημα! Συμπλήρωσε τα στοιχεία πληρωμής σου για να λάβεις την επιστροφή χρημάτων που έχεις συγκεντρώσει.`;
    } else if (userData?.is_under_payment_threshold) {
      return `Η αγορά σου έχει επιβεβαιωθεί από το κατάστημα! Έχεις συγκεντρώσει ${userData?.total_payment_due_cashback_amount} από τις «Προς πληρωμή» αγορές σου. Σου απομένουν ${userData?.payment_threshold_remainder} για να συμπληρώσεις το ελάχιστο ποσό πληρωμής που έχεις δηλώσει και να γίνει η πληρωμή σου.`;
    } else {
      return `Η αγορά σου έχει επιβεβαιωθεί από το κατάστημα! Έχεις συγκεντρώσει ${userData?.total_payment_due_cashback_amount} από τις «Προς πληρωμή» αγορές σου και άρα θα πληρωθείς στις επόμενες προγραμματισμένες πληρωμές μας (${userData?.next_payment}).`;
    }
  };

  useEffect(() => {
    const ongoingSteps = [
      {
        label: 'Σε αναμονή',
        description: selectedPurchase?.validation_interval_weeks
          ? selectedPurchase?.validation_interval_weeks > 1
            ? `Η αγορά έχει καταγραφεί και αναμένεται να αλλάξει σε «Προς πληρωμή» μόλις επιβεβαιωθεί από το κατάστημα σε περίπου ${selectedPurchase?.validation_interval_weeks} εβδομάδες από σήμερα.`
            : `Η αγορά έχει καταγραφεί και αναμένεται να αλλάξει σε «Προς πληρωμή» μόλις επιβεβαιωθεί από το κατάστημα σε περίπου 1 εβδομάδα από σήμερα.`
          : `Η αγορά έχει καταγραφεί και αναμένεται να αλλάξει σε «Προς πληρωμή» μόλις επιβεβαιωθεί από το κατάστημα το συντομότερο δυνατό.`,
        date: moment(selectedPurchase?.date).format('DD/MM/YYYY'),
      },
      {
        label: 'Προς πληρωμή',
        description: extractToBePaidDescription(),
        date: moment(selectedPurchase?.payable_status_date).format('DD/MM/YYYY'),
      },
      {
        label: 'Πληρωμένη',
        description: `Το ποσό επιστροφής από αυτή την αγορά το έλαβες κατά την πληρωμή σου στις ${moment(
          selectedPurchase?.payment_date,
        ).format('DD/MM/YYYY')}. Δες περισσότερα στη σελίδα ‘Πληρωμές’.`,
        date: moment(selectedPurchase?.payment_date).format('DD/MM/YYYY'),
      },
    ];

    const cancelledSteps = [
      {
        label: 'Σε αναμονή',
        description: `Η αγορά έχει καταγραφεί και αναμένεται να αλλάξει σε «Προς πληρωμή» μόλις επιβεβαιωθεί από το κατάστημα σε περίπου 3 εβδομάδες.`,
        date: moment(selectedPurchase?.date).format('DD/MM/YYYY'),
        //TODO stats_payment_speed to be impemented here, not finalized yet
      },
      {
        label: 'Ακυρωμένη',
        description:
          'Η αγορά σου έχει ακυρωθεί από το κατάστημα. Αν θεωρείς ότι ολοκληρώθηκε κανονικά βάσει των όρων του καταστήματος, μπορείς να δημιουργήσεις ένα αίτημα για «Ακυρωμένη αγορά».',
        date: moment(selectedPurchase?.status_date).format('DD/MM/YYYY'),
      },
    ];

    if (selectedPurchase?.purchase_status_id === 2) {
      setActiveStep(1);
      setSteps(cancelledSteps);
    } else if (selectedPurchase?.purchase_status_id === 3) {
      setActiveStep(0);
      setSteps(ongoingSteps);
    } else if (selectedPurchase?.purchase_status_id === 4) {
      setActiveStep(1);
      setSteps(ongoingSteps);
    } else {
      setActiveStep(2);
      setSteps(ongoingSteps);
    }
  }, [selectedPurchase, userData]);

  const handleCloseModal = () => {
    setOpen(false);
    setActiveStep(0);
    setSteps([]);
    setSelectedPurchase(null);
  };

  const getBorder = (index: number) => {
    if (steps.length - 1 === index) {
      return 'none';
    } else if (activeStep > index || activeStep === index) {
      return '2px solid #E9688A';
    } else {
      return '2px solid #ECEFF4';
    }
  };

  const stepIcon = (index: number) => {
    const active = activeStep > index || activeStep === index;
    const className = active ? 'QontoStepIcon-completedIcon' : 'QontoStepIcon-circle';
    return (
      <StyledStepIconRoot ownerState={{ active }} className={className}>
        {active ? <CheckPink /> : <div className="QontoStepIcon-circle" />}
      </StyledStepIconRoot>
    );
  };

  return (
    <IonModal
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => handleCloseModal()}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
    >
      <IonHeader class="ion-no-border">
        {smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
            <Box pr={1} pl={'5px'} py={2} display="flex" gap={2} alignItems="flex-start">
              <IconButton
                sx={{
                  padding: 0,
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                disableRipple
                onClick={() => setOpen(!isOpen)}
              >
                <ChevronLeft />
              </IconButton>
              <Typography variant="body2" component="h2" color={'#313D53'}>
                {selectedPurchase?.shop_name}
              </Typography>
            </Box>
          </IonToolbar>
        )}
        {!smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '24px' }}>
            <IconButton
              disableRipple
              className="iconBtn"
              sx={{
                position: 'absolute',
                right: 12,
                top: 0,
                padding: 0,
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => setOpen(!isOpen)}
            >
              <Close />
            </IconButton>
          </IonToolbar>
        )}
      </IonHeader>
      <Box className="overscroll innerPopup">
        {!smDown && (
          <Typography variant="large900" component="h2" textAlign="center" p={'0 0 16px 0'} color="#313D53">
            {selectedPurchase?.shop_name}
          </Typography>
        )}
        <Box pl={!smDown ? 4 : 2} pr={!smDown ? 3 : 2}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <Box display="flex" flexDirection="row" sx={{ padding: 0, paddingBlock: '4px' }} gap={2}>
                <Box paddingLeft="1px">{stepIcon(index)}</Box>
                <Typography variant="body2" color={'#313D53'} fontWeight={activeStep === index ? 900 : 400}>
                  {step.label}
                </Typography>
              </Box>
              <StepContent
                sx={{
                  [`&.${stepContentClasses.root}`]: {
                    borderLeft: getBorder(index),
                    paddingLeft: '27px',
                    paddingBottom: '16px',
                  },
                  [`&.${stepContentClasses.last}`]: {
                    borderLeft: '0px',
                  },
                }}
              >
                <Box display="flex" flexDirection="column">
                  {step.date && index <= activeStep && (
                    <Typography variant="subtitle1SmallR" color="#6F7787">
                      {step.date}
                    </Typography>
                  )}
                  {step.date && index === activeStep && (
                    <Typography variant="subtitle1SmallR" color="#6F7787">
                      {step.description}
                    </Typography>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Box>
        <Box pl={!smDown ? 4 : 2} pr={!smDown ? 3 : 2} py={2} display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ p: '16px 24px', background: '#F7F7F8', borderRadius: '24px' }}
          >
            <Box display="flex" gap={1} alignItems="center">
              <Box width="24px" height="24px" display="flex" justifyContent="center" alignItems="center">
                <Receipt />
              </Box>
              <Typography variant="subtitle1SmallM" color={'#313D53'}>
                Ποσό αγοράς
              </Typography>
            </Box>
            <Typography color={'#313D53'} variant="body2" component="p">
              {selectedPurchase?.amount}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ p: '16px 24px', background: '#FEF6F1', borderRadius: '56px' }}
          >
            <Box display="flex" gap={1} alignItems="center">
              <Box width="24px" height="24px" display="flex" justifyContent="center" alignItems="center">
                <Euro />
              </Box>
              <Typography variant="subtitle1SmallM" color="#E9688A">
                Ποσό επιστροφής
              </Typography>
            </Box>
            <Typography variant="body2" component="p" color="#E9688A">
              {selectedPurchase?.cashback_amount}
            </Typography>
          </Box>
          {selectedPurchase?.notes !== null && (
            <Box sx={{ p: '16px 24px', background: '#F7F7F8', borderRadius: '24px' }}>
              <Box display="flex" gap={1} alignItems="center">
                <Box width="24px" height="24px" display="flex" justifyContent="center" alignItems="center">
                  <Chat />
                </Box>
                <Typography variant="subtitle1SmallM" color={'#313D53'}>
                  Σχόλια
                </Typography>
              </Box>
              <Typography mt={'16px'} variant="captionSB" component="p" color="#838B98">
                {selectedPurchase?.notes}
              </Typography>
            </Box>
          )}

          <Box>
            <Divider />
          </Box>
          <Accordion
            elevation={0}
            sx={{
              background: '#F7F7F8',
              '&.MuiAccordion-root:before': {
                height: 0,
              },
              '&.MuiAccordion-root': {
                borderRadius: '24px',
              },
              '& .MuiButtonBase-root': {
                padding: '0px 24px',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ '&.MuiAccordionSummary-content': { margin: '18px 0' } }}
            >
              <Box display="flex" gap={1} alignItems="center" py={'6px'}>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <InfoOutlined sx={{ fill: '#313D53' }} />
                </Box>
                <Typography variant="subtitle1SmallM" color={'#313D53'}>
                  Καταστάσεις αγοράς
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ borderRadius: '24px', padding: '6px 0px 24px 0px' }}>
              <PurchaseStatusInfo />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </IonModal>
  );
};

export default PurchaseModal;
