import { isPlatform, useIonRouter } from '@ionic/react';
import { PigogoCardsCarousel, PigogoProductCard, PigogoPromoCard, theme } from 'components';
import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { SimilarShop } from '../../models/SimilarShop';
import { usePromosQuery } from '../../redux/api/queries/promosQueries';
import { Promo } from '../../models/Promo';
import useDebounce from '../../hooks/useDebounce';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { Shop } from '../../models/Shop';
import CustomContainer from '../CustomContainer';
import { useDeleteFavouriteMutation, usePostFavouriteMutation } from '../../redux/api/mutations/favouritesMutation';
import { Link, useHistory } from 'react-router-dom';

const loadArray = [1, 1, 1, 1];

type StoreDetailPageProps = {
  shop?: Shop;
  shopLoading: boolean;
};

const RelatedStores: React.FC<StoreDetailPageProps> = ({ shop, shopLoading }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const history = useHistory();

  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const desktop = isPlatform('desktop');

  const { data: promosData } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const [relatedStores, setRelatedStores] = useState<SimilarShop[]>([]);

  const [postAppend, setPostAppend] = useState<number[]>([]);
  const [deleteAppend, setDeleteAppend] = useState<number[]>([]);

  const [favShops, setFavShops] = useState<number[]>([]);
  const [unfavShops, setUnfavShops] = useState<number[]>([]);

  const [postFavourite, { isError: postFavouriteFailed, isSuccess: postFavouriteSucceeded, reset: resetPostFav }] =
    usePostFavouriteMutation();
  const [
    deleteFavourite,
    { isError: deleteFavouriteFailed, isSuccess: deleteFavouriteSucceeded, reset: resetDeleteFav },
  ] = useDeleteFavouriteMutation();

  const [isDragging, setIsDragging] = useState<any>(false);

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  useEffect(() => {
    if (shop) {
      setRelatedStores(shop.similar_shops);
    }
  }, [shop]);

  useEffect(() => {
    setFavShops(relatedStores.filter((elem) => !!elem.user_favourite).map((elem) => elem.id));
    setUnfavShops(relatedStores.filter((elem) => !elem.user_favourite).map((elem) => elem.id));
  }, [relatedStores]);

  const getSkeletonLoad = () => {
    return (
      <PigogoCardsCarousel>
        {loadArray.map((item, index) => (
          <Box p={2} key={index} display="flex" alignItems="center" justifyContent="center">
            <PigogoProductCard loading={true} logout={!(!!accessToken || sessionLoggedIn)} />
          </Box>
        ))}
      </PigogoCardsCarousel>
    );
  };

  const handlePromoClick = (promo: Promo) => {
    const urlPromo = promo.url;

    router.push(urlPromo);
  };

  const handleSetFavourite = (shop: SimilarShop, index: number) => {
    if ((!!accessToken || sessionLoggedIn) && setFavourite) {
      setFavourite(shop, index);
    }
  };

  const setFavourite = async (shop: SimilarShop, index: number) => {
    if (!shop) {
      return;
    }

    if (!favShops.some((elem) => elem === shop.id) && !postAppend.includes(index)) {
      setPostAppend([...postAppend, index]);

      setFavShops([...favShops, shop?.id]);
      setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));

      const data: any = postFavourite({ shop_id: shop.id, notifications: 1 });
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής προσθήκη αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setUnfavShops([...unfavShops, shop?.id]);
        setFavShops(favShops.filter((elem) => elem !== shop?.id));
      }

      const idx = postAppend.findIndex((elem) => elem === index);
      setPostAppend(postAppend.splice(idx, idx).splice(index, index));
    } else if (favShops.some((elem) => elem === shop.id) && !deleteAppend.includes(index)) {
      setDeleteAppend([...deleteAppend, index]);

      setUnfavShops([...unfavShops, shop?.id]);
      setFavShops(favShops.filter((elem) => elem !== shop?.id));

      const data: any = deleteFavourite(shop.id);
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής αφαίρεση αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShops([...favShops, shop?.id]);
        setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));
      }

      const idx = deleteAppend.findIndex((elem) => elem === index);
      setDeleteAppend(deleteAppend.splice(idx, idx).splice(index, index));
    }

    return;
  };

  const getShops = () => {
    if (shop && relatedStores) {
      return (
        <PigogoCardsCarousel
          beforeChange={(previousSlide, state) => setIsDragging(true)}
          afterChange={(previousSlide, state) => setIsDragging(false)}
        >
          {relatedStores.map((shop: SimilarShop, index: number) => {
            return (
              <Box
                onDragStart={(e) => {
                  setIsDragging(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragEnd={(e) => {
                  if (isDragging) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  setTimeout(() => {
                    setIsDragging(false);
                  }, 3500);
                }}
                onDrag={(e) => {
                  setIsDragging(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrop={(e) => {
                  setTimeout(() => {
                    setIsDragging(false);
                  }, 3500);
                }}
                p={tablet ? '4px' : 1}
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {index === 3 && promosData !== undefined ? (
                  <a
                    className={'storeCard'}
                    href={!isDragging ? promosData.data[0].url : undefined}
                    onClick={(e: any) => e.preventDefault()}
                  >
                    <PigogoPromoCard
                      productType
                      logo={promosData.data[0].image}
                      color={promosData.data[0].color}
                      title={promosData.data[0]?.tile_title}
                      onClick={() => {
                        !isDragging && history.push(`${promosData.data[0].url}`);
                      }}
                      call_to_action={
                        !tablet ? promosData.data[0].call_to_action : promosData.data[0].call_to_action_mobile
                      }
                    />
                  </a>
                ) : (
                  <a className={'storeCard'} href={!isDragging ? `/${shop.slug.name}` : undefined}>
                    <PigogoProductCard
                      shop={{
                        ...shop,
                        user_favourite: favShops?.some((elem) => elem === shop?.id),
                      }}
                      loading={false}
                      logout={!(!!accessToken || sessionLoggedIn)}
                      handleClick={(e: any) => {
                        e.preventDefault();
                        !isDragging && history.push(`/${shop.slug.name}`);
                      }}
                      setFavourite={() => handleSetFavourite(shop, index)}
                    />
                  </a>
                )}
              </Box>
            );
          })}
        </PigogoCardsCarousel>
      );
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={6} marginTop={tablet ? 4 : 10} marginBottom={tablet ? 12 : 18}>
      <Box textAlign={'center'}>
        <Typography variant="smallB" component="h2" color={'#1D2532'}>
          Σχετικά καταστήματα
        </Typography>
      </Box>
      <Box
        onMouseOut={(e) => {
          if (isDragging) {
            e.preventDefault();
            e.stopPropagation();
          }
          setTimeout(() => {
            setIsDragging(false);
          }, 1500);
        }}
      >
        <CustomContainer sx={{ paddingInline: 0 }}>{shopLoading ? getSkeletonLoad() : getShops()}</CustomContainer>
      </Box>
    </Box>
  );
};

export default RelatedStores;
