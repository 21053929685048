import { useIonRouter, useIonViewWillEnter } from '@ionic/react';
import React, { FC, useEffect, useState } from 'react';
import { PigogoButton, PigogoCardsCarousel, PigogoProductCard, PigogoPromoCard, theme } from 'components';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import FavouritesTable from './FavouritesTable';
import { useShopsQuery } from '../../redux/api/queries/shopQuery';
import { useDeleteFavouriteMutation, usePostFavouriteMutation } from '../../redux/api/mutations/favouritesMutation';
import { useAppSelector } from '../../redux/hooks';
import { usePromosQuery } from '../../redux/api/queries/promosQueries';
import { Promo } from '../../models/Promo';
import { Favourite } from '../../models/Favourite';
import { useDispatch } from 'react-redux';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import CustomContainer from '../CustomContainer';
import { useHistory } from 'react-router';
import { SimilarShop } from '../../models/SimilarShop';

const loadArray = [1, 1, 1, 1];

interface FavouritesProps {
  favourites: Favourite[];
  loading: boolean;
  showCarousel: boolean;
}

const FavouriteContent: FC<FavouritesProps> = ({ favourites, loading, showCarousel }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const history = useHistory();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const xlUp = useMediaQuery(theme.breakpoints.up('extraLg'));

  const [postAppend, setPostAppend] = useState<number[]>([]);
  const [deleteAppend, setDeleteAppend] = useState<number[]>([]);

  const [favShops, setFavShops] = useState<number[]>([]);
  const [unfavShops, setUnfavShops] = useState<number[]>([]);

  const [triggerFetch, setTriggerFetch] = useState<boolean>(true);

  const [postFavourite, { isError: postFavouriteFailed, isSuccess: postFavouriteSucceeded }] =
    usePostFavouriteMutation();
  const [deleteFavourite, { isError: deleteFavouriteFailed, isSuccess: deleteFavouriteSucceeded }] =
    useDeleteFavouriteMutation();

  const { data: promos, isError: errorPromo } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const { data: shops, isLoading: shopsLoad, isError: errorShops } = useShopsQuery({ limit: 11 });

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [carouselData, setCarouselData] = useState<any[]>([]);
  const [isDragging, setIsDragging] = useState<any>(false);

  useEffect(() => {
    if (deleteFavouriteFailed || postFavouriteFailed) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    }
  }, [deleteFavouriteFailed, postFavouriteFailed, errorShops, errorPromo]);

  useEffect(() => {
    const finalData: any[] = [];

    if (shops) {
      shops.data.map((shop) => {
        const {
          image = 'N/A',
          name = 'N/A',
          cashback = 'N/A',
          currency = 'N/A',
          strikedCashback = null,
          strikedCurrency = null,
          user_favourite = null,
          savings = { coupon_count: undefined, offer_count: undefined },
          promos = { has_Promos: false, has_double: false },
          cashback_categories_count = null,
        } = shop ? shop : {};

        finalData.push({
          type: 'shop',
          id: shop.id,
          image: image,
          name: name,
          promos: promos,
          cashback,
          currency,
          strikedCashback,
          strikedCurrency,
          cashback_categories_count,
          savings: savings,
          logout: !(!!accessToken || sessionLoggedIn),
          slugName: shop.slug.name,
          hotOffer: promos.has_double,
          favourites: !!user_favourite,
          user_favourite: user_favourite,
          discount: `${cashback}${currency}`,
          tag: savings.offer_count ? savings.offer_count : null,
          ticket: savings.coupon_count ? savings.coupon_count : null,
          deleteDisc: strikedCashback ? `${strikedCashback}${strikedCurrency}` : undefined,
        });
      });
    }

    if (promos && promos.data) {
      const promoData = {
        type: 'promo',
        logo: promos.data[0].image,
        label: promos.data[0].label,
        tile_title: promos.data[0].tile_title,
        url: promos.data[0].url,
        promo_type_id: promos.data[0].promo_type_id,
        call_to_action: promos.data[0].call_to_action,
        call_to_action_mobile: promos.data[0].call_to_action_mobile,
      };

      finalData.splice(3, 0, promoData);
    }

    setCarouselData(finalData);
  }, [promos, shops]);

  const setFavourite = async (shop: SimilarShop, index: number) => {
    if (!shop) {
      return;
    }

    if (!favShops.some((elem) => elem === shop.id) && !postAppend.includes(index)) {
      setPostAppend([...postAppend, index]);

      setFavShops([...favShops, shop?.id]);
      setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));

      const data: any = await postFavourite({ shop_id: shop.id, notifications: 1 });
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής προσθήκη αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setUnfavShops([...unfavShops, shop?.id]);
        setFavShops(favShops.filter((elem) => elem !== shop?.id));
      }

      const idx = postAppend.findIndex((elem) => elem === index);
      setPostAppend(postAppend.splice(idx, idx).splice(index, index));
    } else if (favShops.some((elem) => elem === shop.id) && !deleteAppend.includes(index)) {
      setDeleteAppend([...deleteAppend, index]);

      setUnfavShops([...unfavShops, shop?.id]);
      setFavShops(favShops.filter((elem) => elem !== shop?.id));

      const data: any = await deleteFavourite(shop.id);
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής αφαίρεση αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShops([...favShops, shop?.id]);
        setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));
      }

      const idx = deleteAppend.findIndex((elem) => elem === index);
      setDeleteAppend(deleteAppend.splice(idx, idx).splice(index, index));
    }

    return;
  };

  const handleSetFavourite = async (shop: SimilarShop, index: number) => {
    if ((!!accessToken || sessionLoggedIn) && setFavourite) {
      await setFavourite(shop, index);
    }
  };

  const handlePromoClick = (promo: Promo) => {
    !isDragging && history.push(`${promo.url}`);
  };

  const storeCards = () => {
    if (carouselData && !shopsLoad) {
      return carouselData.map((item: any, index: number) => {
        return (
          <Box
            onDragStart={(e) => {
              setIsDragging(true);
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnd={(e) => {
              if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
              }
              setTimeout(() => {
                setIsDragging(false);
              }, 3500);
            }}
            onDrag={(e) => {
              setIsDragging(true);
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              setTimeout(() => {
                setIsDragging(false);
              }, 3500);
            }}
            mx={xlUp ? '12px' : mdUp ? 1 : 0.5}
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: 'pointer' }}
          >
            {item.type === 'promo' ? (
              <a
                className={'storeCard'}
                href={!isDragging ? item.url : undefined}
                onClick={(e: any) => e.preventDefault()}
              >
                <PigogoPromoCard
                  productType
                  logo={item.logo}
                  color={item.color}
                  title={item?.tile_title}
                  call_to_action={!mdDown ? item.call_to_action : item.call_to_action_mobile}
                  onClick={() => handlePromoClick(item)}
                />
              </a>
            ) : (
              <a className={'storeCard'} href={!isDragging ? `/${item.slugName}` : undefined}>
                <PigogoProductCard
                  shop={{
                    ...item,
                    user_favourite: favShops?.some((elem) => elem === item?.id),
                  }}
                  loading={false}
                  handleClick={(e: any) => {
                    e.preventDefault();
                    !isDragging && router.push(`/${item.slugName}`);
                  }}
                  setFavourite={async () => {
                    await handleSetFavourite(item, index);
                    setTriggerFetch(true);
                  }}
                />
              </a>
            )}
          </Box>
        );
      });
    }

    if (shopsLoad) {
      loadArray.map((load, index: number) => {
        return (
          <Grid item xs={6} md={4} lg={5} key={index} display="flex" alignItems="center" justifyContent="center">
            <PigogoProductCard loading={true} logout={!(!!accessToken || sessionLoggedIn)} />
          </Grid>
        );
      });
    }

    if (!shopsLoad && !shops) {
      return <Typography textAlign="center">Δεν υπάρχουν καταστήματα</Typography>;
    }
  };

  const getWidth = () => {
    if (xlUp) {
      return 'calc(100% + 24px)';
    }
    if (mdUp) {
      return 'calc(100% + 16px)';
    }
    return 'calc(100% + 8px)';
  };

  const getMargin = () => {
    if (xlUp) {
      return '-12px';
    }
    if (mdUp) {
      return '-8px';
    }
    return '-4px';
  };

  const getNoData = () => {
    return (
      <CustomContainer paddingTop={7} paddingBottom={10}>
        <style>
          {`
        .react-multi-carousel-dot-list {
          height: 0;
        }
        `}
        </style>
        <Box display={'flex'} flexDirection={'column'}>
          {smDown ? (
            <Box>
              <Typography variant={'subtitle1'} component="h2">
                Προτεινόμενα καταστήματα
              </Typography>
            </Box>
          ) : (
            <Box display={'flex'} flexDirection={'column'} textAlign={'center'} pt={3} gap={2}>
              <Typography variant={'smallB'} component="h2" color={'#1D2532'}>
                Προτεινόμενα καταστήματα
              </Typography>
              <Typography variant={'body2MediumL'} component="p" color={'#1D2532'}>
                Στο Pigogo θα βρεις τις καλύτερες προσφορές των καταστημάτων.
              </Typography>
            </Box>
          )}
          <Box paddingTop={smDown ? 3 : 6} sx={{ width: getWidth(), marginLeft: getMargin() }}>
            {shops && (
              <PigogoCardsCarousel
                beforeChange={(previousSlide, state) => setIsDragging(true)}
                afterChange={(previousSlide, state) => setIsDragging(false)}
              >
                {storeCards()}
              </PigogoCardsCarousel>
            )}
          </Box>
          {!smDown && (
            <Box paddingTop={14} textAlign={'center'}>
              <PigogoButton
                variation="secondary"
                sx={{ width: '327px' }}
                text="Δες τα καταστήματα"
                onClick={() => router.push(`/stores`)}
              />
            </Box>
          )}
        </Box>
      </CustomContainer>
    );
  };

  const getFavourites = () => {
    return (
      <CustomContainer>
        <Box paddingTop={smDown ? 4 : 10} paddingBottom={smDown ? 4 : 16}>
          <FavouritesTable triggerFetch={triggerFetch} setTriggerFetch={setTriggerFetch} />
        </Box>
        {showCarousel && (
          <Box display={'flex'} flexDirection={'column'} paddingBottom={10}>
            {smDown ? (
              <Box>
                <Typography variant={'subtitle1'}>Προτεινόμενα καταστήματα</Typography>
              </Box>
            ) : (
              <Box display={'flex'} flexDirection={'column'} textAlign={'center'} pt={3} gap={2}>
                <Typography variant={'smallB'} color={'#1D2532'}>
                  Προτεινόμενα καταστήματα
                </Typography>
                <Typography variant={'body2MediumL'} color={'#1D2532'}>
                  Στο Pigogo θα βρεις τις καλύτερες προσφορές των καταστημάτων.
                </Typography>
              </Box>
            )}
            <Box paddingTop={smDown ? 3 : 6}>{shops && <PigogoCardsCarousel>{storeCards()}</PigogoCardsCarousel>}</Box>
            {!smDown && (
              <Box paddingTop={14} textAlign={'center'}>
                <PigogoButton
                  variation="secondary"
                  sx={{ width: '327px' }}
                  text="Δες τα καταστήματα"
                  onClick={() => router.push(`/stores`)}
                />
              </Box>
            )}
          </Box>
        )}
      </CustomContainer>
    );
  };

  return favourites.length > 0 || loading ? getFavourites() : getNoData();
};

export default FavouriteContent;
